::-moz-selection {
  background: #1d2646;
  color: #f2e04f;
}

::selection {
  background: #1d2646;
  color: #f2e04f;
}


.page {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: auto;
  background-color: #161616;
}

.first {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f2e04f;
  padding-top: 10vh;
}

.first .child {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10vh;
  padding-bottom: 10vh;
}


.minion {
  width: 300px;
  max-width: 30vw;
  height: fit-content;
  border-radius: 17px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  margin-left: auto;
  margin-right: auto;
  transition: all.2s ease-out;
}

.minion:hover {
  transform: scale(.9);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 45px 90px -18px, rgba(0, 0, 0, 0.3) 0px 27px 54px -27px, rgba(10, 37, 64, 0.35) 0px -1.8px 5.4px 0px inset;
  cursor: pointer;
}

.minion img {
  width: 100%;
  border-radius: 17px;
  height: auto;
  user-select: none;
}

.App {
  z-index: 4;
  margin: 0;
  margin-top: 100vh;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: #161616;
  display: flex;
  flex-wrap: wrap;
}

.App div {
  border: none;
}

.App div:hover {
  cursor: pointer;
}

.App div:hover .box {
  filter: invert(1);
  background-color: #0d1fb0;
  border-color: #0d1fb0;
}

h1{
  text-align: center;
  color: #1d2646;
}

h2,
h2 a {
  text-align: center;
  text-decoration: none;
  color: rgb(207, 181, 61);
}

h2 a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.leger {
  font-weight: 400;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 650px) {

  .App div {
    width: 100% !important;
    height: calc(100% / 6) !important;
  }

  .App div:first-child .box {
    border-top: none !important;
  }
  
  .App div .box {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  .box h1 {
    font-size: 2em !important;
  }

}

@media only screen and (max-width: 1350px) {

  .App div {
    width: calc(100% / 2);
    height: calc(100% / 3);
  }

}

@media only screen and (min-width: 1350px) {

  .App div {
    width: calc(100% / 3);
    height: calc(100% / 2);
  }

}

@media only screen and (min-width: 1054px) {

  .App div .box {
    transition: background .2s, filter .2s;
  }

}

.App div .box {
  background-color: rgb(22, 22, 22,.77);
  position: relative;
  margin: 0;
  padding: 0;
  width: calc(100% - 2px) !important;
  height: calc(100% - 2px) !important;
  border: 2px solid #f2e04f;
}

.box h1 {
  margin: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  font-size: 3em;
  color: #f8f8ff;
  transform: translateY(-50%);
}

.box span {
  position: absolute;
  bottom: 1vw;
  left: 0;
  font-size: 1.2em;
  color: #f2e04f;
  font-style: italic;
  width: 100%;
  text-align: center;
}